import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";
import Menu from "./Menu";
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { Icon } from '@iconify/react';
import facebookIcon from '@iconify/icons-gg/facebook';
import twitterFill from '@iconify/icons-akar-icons/twitter-fill';
import googlePlusOutlined from '@iconify/icons-ant-design/google-plus-outlined';
import youtubeFill from '@iconify/icons-akar-icons/youtube-fill';
import bxlLinkedin from '@iconify/icons-bx/bxl-linkedin';
import bxsPhone from '@iconify/icons-bx/bxs-phone';
import envelopeFill from '@iconify/icons-bi/envelope-fill';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const weekFormat = 'MM/DD';
const monthFormat = 'YYYY/MM';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const customFormat = value => `custom format: ${value.format(dateFormat)}`;

const customWeekStartEndFormat = value =>
  `${moment(value).startOf('week').format(weekFormat)} ~ ${moment(value)
    .endOf('week')
    .format(weekFormat)}`;



const ParticipantDetails = () =>{
    const [startDate, setStartDate] = useState(new Date());

    return (
        <>
<div className="container-fluid up">
    <div className="upperheader">
        <div className="row">
            <div className="col-md-6">
                <div className="leftside">
                   <span>Follow Us: </span>
                    <span className="icons pd"> <Icon icon={facebookIcon} /> </span>
                    <span className="icons pd"> <Icon icon={twitterFill} /> </span>
                    <span className="icons pd"> <Icon icon={googlePlusOutlined} /> </span>
                    <span className="icons pd"> <Icon icon={youtubeFill} /> </span>
                    <span className="icons pd"> <Icon icon={bxlLinkedin} /> </span>
                </div>
            </div>
            <div className="col-md-6">
            <div className="rightside">
            <span className="phonee  pd"> <Icon  icon={bxsPhone} /> Phone (647) 525-7466 </span>
           
            <span className="icons pd"> <Icon icon={envelopeFill} /> Message info@pixiders.com</span>
            </div>
            </div>
        </div>
    </div>
    <div className="lowerheader">
    {/* <Navbar bg="light" expand="lg">

    <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      <Nav
        className="me-auto my-2 my-lg-0"
        style={{ maxHeight: '100px' }}
        navbarScroll
      >
        <Nav.Link href="#action1">Home</Nav.Link>
        <Nav.Link href="#action2">Link</Nav.Link>

        <Nav.Link href="#" disabled>
          Link
        </Nav.Link>
      </Nav>

    </Navbar.Collapse>
  
</Navbar> */}
    </div>
</div>


        </>
)}

export default ParticipantDetails;











