import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from '@iconify/react';
import print20Filled from '@iconify/icons-fluent/print-20-filled';
import documentExport from '@iconify/icons-carbon/document-export';
import Menu from "./Menu";
import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const weekFormat = 'MM/DD';
const monthFormat = 'YYYY/MM';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const customFormat = value => `custom format: ${value.format(dateFormat)}`;

const customWeekStartEndFormat = value =>
  `${moment(value).startOf('week').format(weekFormat)} ~ ${moment(value)
    .endOf('week')
    .format(weekFormat)}`;



const ParticipantOrderHistory = () =>{
    const [startDate, setStartDate] = useState(new Date());

    return (
        <>

    
        <div className="container-fluid fpage">
<div className="row">
    <div className="col-md-5 fpage">
<img src="https://otb-demo.com/images/logo.png" className="logo fpage" />
    </div>
    <div className="col-md-7 fpage">
<div className="cart">
    <span className="shop"><a href="#">Shoping Cart [99]</a></span>
    <span className="logout"><a href="/"> Logout</a> </span>
</div>
<ul className="pointsdetail">
    <li>11,999 <span className="pointsav">Available Points</span></li>
    <li>12,435 <span className="pointsav"> Points Earned</span></li>
    <li>34,455 <span className="pointsav"> Points Redeemed</span></li>
</ul>

    </div>
</div>






        </div>


<Menu/>

<div className="container-fluid poh">
<h1>Order History</h1>
<p>We're excited to bring you a revolutionary incentive program that is unparalled in the industary. We're excited to bring you a revolutionary incentive program that is unparalled in the industary!</p>
   
  


<div className="pointsdetails">


<div className="row">
    <div className="col-md-6 date poh">
<div className="row">
    <div className="col-md-8 date poh">
    <Space direction="vertical" size={12}>
<RangePicker defaultValue={[moment('2021/12/06', dateFormat), moment('2021/12/09', dateFormat)]} format={dateFormat}/>
    </Space>
    </div>
    <div className="col-md-4">
    </div>
</div>
</div>
    <div className="col-md-6 poh">
    <div className="row">
    <div className="col-md-1"></div>
    <div className="col-md-9 poh">
        <span className="threemonth poh">3 mo</span>
        <span className="sixmonth poh">6 mo</span>
        <span className="tewlvemonth poh">12 mo</span>
    </div>
    <div className="col-md-1 poh">
    <a><Icon icon={documentExport} className="doc poh" /></a>
    </div>
    <div className="col-md-1 poh">
    <a>
    <Icon icon={print20Filled} className="print poh" />
    </a>
</div>
    </div>
        </div>
</div>





<table className="table">
                        <thead className="poh">
                            <tr>
                             
                                <th>Transaction Description</th>
                                <th>Points</th>
                                <th>Point Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="tablerowcolor poh">
                              
                                <td>Product Purchase: Order # 3605778
                                <p className="tableline">Wednessday, September 13, 2017 04:05PM</p>
                                </td>
                                <td>-50</td>
                                <td>97,403</td>
                            </tr>

                            <tr >
                               
                            <td>Product Purchase: Order # 3605778
                                <p className="tableline">Monday, September 11, 2017:04:05PM</p>
                                </td>
                                <td>-1500</td>
                                <td>98,453</td>
                            </tr>
                            <tr className="tablerowcolor poh">
                            
                            <td><span className="pointrefund">Point Refund: Order # 12345</span>
                                <p className="tableline">Monday, September 04, 2017:04:05PM</p>
                                </td>
                                <td className="pointrefund">500</td>
                                <td>99,953</td>
                            </tr>
                            <tr >
                     
                            <td>Product Purchase: Order # 17898
                                <p className="tableline">Monday, September 04, 2017:04:05PM</p>
                                </td>
                                <td>-500</td>
                                <td>99,453</td>
                            </tr>
                            <tr className="tablerowcolor poh">
                            
                            <td>Product Purchase: Order # 12345
                                <p className="tableline">Wednessday, September 13, 2017 04:05PM</p>
                                </td>
                                <td>-500</td>
                                <td>99,953</td>
                            </tr>
                            <tr >
                            
                            <td>Product Purchase: Order # 3605778
                                <p className="tableline">Saturday October 05, 2017:11:12AM</p>
                                </td>
                                <td>-1,000</td>
                                <td>100,453</td>
                            </tr>
                            <tr className="tablerowcolor poh">
                            
                            <td>Product Purchase: Order # 3605778
                                <p className="tableline">Friday, October 04, 2017:06:35PM</p>
                                </td>
                                <td>-10,000</td>
                                <td>110,453</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="tableline poh">~ These totals are estimates and may change</p>




</div>




</div>




        </>
)}

export default ParticipantOrderHistory;