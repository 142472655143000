import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Gift_Card from "./Gift_Card.png";
import Merchandise from "./Merchandise.png";
import Purchase from "./Purchase.png";
import Travel from "./Travel.png";
import Menu from "./Menu";


const FirstPageNew = () =>{



    return (
        <>
        {/* <img src={underconstruction} className="underconst"/> */}
        <div className="container-fluid fpage">
<div className="row">
    <div className="col-md-5 fpage">
<img src="https://otb-demo.com/images/logo.png" className="logo fpage" />
    </div>
    <div className="col-md-7 fpage">
<div className="cart">
    <span className="shop"><a href="#">Shoping Cart [99]</a></span>
    <span className="logout"><a href="/"> Logout</a> </span>
</div>
<ul className="pointsdetail">
    <li>11,999 <span className="pointsav">Available Points</span></li>
    <li>12,435 <span className="pointsav"> Points Earned</span></li>
    <li>34,455 <span className="pointsav"> Points Redeemed</span></li>
</ul>

    </div>
</div>






        </div>


<Menu/>

<div className="centraldata fpage">
    <p className="fisrtline">Welcome to MyTraneRewards.You've Earned it-Treat Yourself</p>
    <p>We're excited to bring you a revolutionary incentive program that is unparalled in the industary!</p>
    <p>With such a huge selection of rewards, you're sure to find something special.</p>
    <button className="centralbutton">View Rewards</button>


    <div className="imagebox">
    <div className="row">
    <div className="col-md-3 fpage">
            <h2 className="pro">Promotions</h2>
            <img src={Purchase} />
            <p className="pimage fpage">Get huge saving on our special promotions.</p>
        </div>
        <div className="col-md-3 fpage">
            <h2 className="pro">Merchandise</h2>
            <img src={Merchandise} />
            <p className="pimage fpage">Choose from thousands ofhousehold and fasion item.</p>
        </div>
        <div className="col-md-3 fpage">
            <h2 className="pro">Gift Cards</h2>
            <img src={Gift_Card} />
            <p className="pimage fpage">Take advantage of our wide range of gift cards.</p>
        </div>
        <div className="col-md-3 fpage">
            <h2 className="pro">Travel</h2>
            <img src={Travel} />
            <p className="pimage fpage">Escape with us, plan your next holiday and save on travel.</p>
        </div>


    </div>
</div>



</div>




        </>
)}

export default FirstPageNew;