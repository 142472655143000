import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Switch,
  Route,
  Routes,
  Router,
  HashRouter,
  Redirect,
  Navigate
} from "react-router-dom";
import FirstPage from "./Components/FirstPage";
import ForgetPass from "./Components/ForgetPass";
import LoginPage from "./Components/LoginPage";
import ImportCsvFilesForUsers from "./Components/ImportCsvFilesForUsers";
import AddEmail from "./Components/AddEmail";
import ManageEmails from "./Components/ManageEmails";
import ManagePoNumber from "./Components/ManagePoNumber";
import ViewAllOrder from "./Components/ViewAllOrder";
import Banner from "./Components/Banner";
import FirstPageNew from "./Components/FirstPageNew";
import ParticipantPointsHistory from "./Components/ParticipantPointsHistory";
import ParticipantOrderHistory from "./Components/ParticipantOrderHistory";
import ParticipantDetails from "./Components/ParticipantDetails";
import Index from "./Components/Index";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<LoginPage/>} />
      <Route path="/FirstPageNew" element={<FirstPageNew/>} />
      <Route path="/ParticipantOrderHistory" element={<ParticipantOrderHistory/>} />
      <Route path="/ParticipantPointsHistory" element={<ParticipantPointsHistory/>} />
      <Route path="/ParticipantDetails" element={<ParticipantDetails/>} />
      <Route path="/Index" element={<Index/>} />
      

      
      {/* <Route path="/FirstPage" element={<FirstPage/>} /> 
      <Route path="/ForgetPass" element={<ForgetPass/>} />
      <Route path="/ImportCsvFilesForUsers" element={<ImportCsvFilesForUsers/>} />
      <Route path="/AddEmail" element={<AddEmail/>} />
      <Route path="/ManageEmails" element={<ManageEmails/>} />
      <Route path="/ManagePoNumber" element={<ManagePoNumber/>} />
      <Route path="/ViewAllOrder" element={<ViewAllOrder/>} />
      <Route path="/Banner" element={<Banner/>} /> */}

      
  {/* <Route path="*" exact={true} component={RegisterForm} /> */}
  
      </Routes>
    </BrowserRouter>
  );
}

export default App;
